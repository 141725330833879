import {Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {
    GetIncomeItemsQuery,
    IncomeItem,
    IncomeType,
    useGetIncomeItemsLazyQuery,
    useGetIncomeTypesQuery
} from "../../graphql/generated/graphql";
import {Add} from "@mui/icons-material";
import IncomeItemDisplayEdit from "./IncomeItemDisplayEdit";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import ErrorDisplay from "../../common/ErrorDisplay";
import useAnonCookie from "../../security/useAnonCookie";
import {ValidationResult} from "./IncomeItemEdit";
import {FetchResult} from "@apollo/client";
import OneTwoPayTooltip from "../../common/OneTwoPayTooltip";
import theme from "../../theme";
import {getIncomeType} from "./incomeTypeFunctions";
import {assumedUserContext} from "../User/AssumedUserContext";

type PropsType = {
    payStubId: number | undefined;
    isEditable: boolean;
    reload: boolean;
    reloaded: () => void;
    incomeItemsUpdated: () => void;
}

const REGULAR_INCOME_TYPE_ID = "1";
const CASH_BENEFIT_INCOME_TYPE_ID = "7";

function getEmptyIncomeLine(): Array<IncomeItem> {
    const regularIncome: IncomeItem = {
        amount: "00.00",
        currentPeriodItemId: "",
        id: "", // need unique ids if providing more than one income line.
        incomeTypeId: REGULAR_INCOME_TYPE_ID,
        priorPeriodAmount: "00.00",
        priorPeriodItemId: "",
        ytdAmount: "00.00",
        rate: "00.00",
        units: "00.00",
        payStubId: 0,
    }
    return [regularIncome];
}

function getEmptyBenefitLine(): Array<IncomeItem> {
    const cashBenefit: IncomeItem = {
        amount: "00.00",
        currentPeriodItemId: "",
        id: "", // need unique ids if providing more than one income line.
        incomeTypeId: CASH_BENEFIT_INCOME_TYPE_ID,
        priorPeriodAmount: "00.00",
        priorPeriodItemId: "",
        ytdAmount: "00.00",
        rate: "00.00",
        units: "00.00",
        payStubId: 0,
    }
    return [cashBenefit];
}

function getTableBodyForIncomeLines(incomeItemsLoading: boolean,
                                    incomeLines: IncomeItem[],
                                    incomeTypes: Array<IncomeType>,
                                    deleteIncomeLine: (id: string) => void,
                                    validateIncomeType: (incomeTypeId: string, incomeItemId: string) => ValidationResult,
                                    incomeItemUpdated: () => void,
                                    isEditable: boolean
) {

    return <>
        {!incomeItemsLoading && !!incomeLines &&
            incomeLines
                .sort((a, b) =>
                    (getIncomeType(a.incomeTypeId, incomeTypes)?.displayOrder || 0)
                    - (getIncomeType(b.incomeTypeId, incomeTypes)?.displayOrder || 0)
                )
                .map((incomeLine) => {
                        return (
                            <TableRow key={incomeLine.id}>
                                <IncomeItemDisplayEdit
                                    isEditable={isEditable}
                                    incomeItem={incomeLine}
                                    incomeTypes={incomeTypes}
                                    deleteItem={deleteIncomeLine}
                                    validateIncomeType={validateIncomeType}
                                    incomeItemUpdated={incomeItemUpdated}
                                />
                            </TableRow>
                        );
                    }
                )}
    </>
}

function getIncomeItemTableHeader(addNewLineMethod: () => void,
                                  headerLabel: string,
                                  addLineTooltip: string,
                                  isEditable: boolean
) {

    const addIcon = React.forwardRef<HTMLButtonElement>((props, ref) => (
        <IconButton
            {...props}
            ref={ref}
            color={"primary"}
            sx={{ml: 1}}
            onClick={addNewLineMethod}
        >
            <Add/>
        </IconButton>
    ));
    return <Box
        display="flex"
        alignItems="center"
    >
        <Typography variant="h6" sx={{fontWeight: '500'}}>
            {headerLabel}
        </Typography>
        {
            isEditable &&
            <OneTwoPayTooltip
                tipContent={addLineTooltip}
                TooltipTarget={addIcon}
            />
        }
    </Box>
}

const EditableIncomeLines = (props: PropsType) => {

    const {
        payStubId,
        reload,
        reloaded,
        incomeItemsUpdated,
        isEditable
    } = props;

    const {getAnonUserId} = useAnonCookie();
    const {assumedUser} = useContext(assumedUserContext);

    const [incomeLines, setIncomeLines] = useState<Array<IncomeItem>>([]);

    const {
        data: incomeTypesData,
        error: incomeTypesError,
        loading: incomeTypesLoading,
    } = useGetIncomeTypesQuery({
        variables: {
            anonUserId: getAnonUserId(),
            userId: assumedUser.id
        }
    });

    const [
        getIncomeItems,
        {
            data: incomeItemsData,
            loading: incomeItemsLoading,
            error: incomeItemsError
        }] = useGetIncomeItemsLazyQuery({
        fetchPolicy: "no-cache"
    });

    const loadIncomeItems = useCallback(() => {
        if (incomeItemsLoading || !!incomeItemsError) {
            return;
        }
        if (!payStubId) {
            return;
        }
        getIncomeItems({
            variables: {
                payStubId: payStubId,
                anonUserId: getAnonUserId()
            }
        })
            .then((getIncomeItemsResult: FetchResult<GetIncomeItemsQuery>) => {
                let incomeItemsFromServer = getIncomeItemsResult.data?.getIncomeItems;
                reloaded();
                if (!!incomeItemsFromServer && incomeItemsFromServer.length > 0) {
                    setIncomeLines(incomeItemsFromServer);
                } else {
                    setIncomeLines(getEmptyIncomeLine());
                }
            });
    }, [getAnonUserId, reloaded, payStubId, incomeItemsError, incomeItemsLoading, getIncomeItems]);

    useEffect(() => {
        if (!incomeItemsData || reload) {
            loadIncomeItems();
        }
    }, [incomeItemsData, loadIncomeItems, reload]);

    const incomeItemsChanged = useCallback(() => {
        setIncomeLines(getEmptyIncomeLine());
        loadIncomeItems();
        incomeItemsUpdated();
    }, [incomeItemsUpdated, loadIncomeItems]);

    if (!!incomeTypesError || incomeTypesLoading) {
        return <LoadingErrorDisplay
            loading={incomeTypesLoading}
            apolloError={incomeTypesError}
        />
    }

    const incomeTypes = incomeTypesData?.getIncomeTypes;
    if (!incomeTypes || incomeTypes.length < 1) {
        return <ErrorDisplay
            stringError={"Error loading income types. Please try again or contact support."}
        />
    }

    const addNewIncomeLine = () => {
        setIncomeLines(prevState => {
            return [
                ...prevState,
                ...getEmptyIncomeLine()
            ];
        });
    }

    const addNewBenefitLine = () => {
        setIncomeLines(prevState => {
            return [
                ...prevState,
                ...getEmptyBenefitLine()
            ];
        });
    }

    const deleteIncomeLine = (id: string) => {
        setIncomeLines(prevState => {
            if (prevState.length < 2) {
                return [];
            }
            return prevState
                .filter(incomeLine => incomeLine.id !== id);
        });
    }

    const validateIncomeType = (incomeTypeId: string, incomeItemId: string): ValidationResult => {
        const otherIncomeItems = incomeLines.filter(incomeLine => incomeLine.id !== incomeItemId);
        const duplicateIncomeTypeItem = otherIncomeItems.find(incomeLine => incomeLine.incomeTypeId === incomeTypeId);
        if (!!duplicateIncomeTypeItem) {
            const incomeType = getIncomeType(incomeTypeId, incomeTypes);
            return {
                valid: false,
                message: `Only one line of ${incomeType?.label} income can be added to each pay stub. Please use a different income type or create a new one  if needed.`
            }
        }
        return {
            valid: true
        }
    }

    const incomeItemsOnlyLines = incomeLines
        .filter(incomeLine =>
            !getIncomeType(incomeLine.incomeTypeId, incomeTypes)?.isBenefit
        );

    const benefitItemsOnlyLines = incomeLines
        .filter(incomeLine =>
            getIncomeType(incomeLine.incomeTypeId, incomeTypes)?.isBenefit
        );

    const hasBenefits = benefitItemsOnlyLines.length > 0;

    return <>
        <Table
            size="small"
            sx={{
                mt: 0,
                '& .MuiTableCell-root': {borderBottom: 'none'},
                "& .MuiTableRow-root:hover": {backgroundColor: theme.palette.action.hover, borderRadius: '5px'},
            }}
        >
            <TableHead>
                <TableRow sx={{":hover": {backgroundColor: 'transparent !important'}}}>
                    <TableCell>
                        {/*Income Type*/}
                        {getIncomeItemTableHeader(addNewIncomeLine, "Income Items", "Add Additional Income Item", isEditable)}
                    </TableCell>
                    <TableCell>
                        <Typography variant="h6" sx={{fontWeight: '500'}}>
                            Current Period
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="h6" sx={{fontWeight: '500'}}>
                            YTD
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>

                {getTableBodyForIncomeLines(incomeItemsLoading, incomeItemsOnlyLines, incomeTypes, deleteIncomeLine, validateIncomeType, incomeItemsChanged, isEditable)}

                {hasBenefits && <>
                    <TableRow sx={{":hover": {backgroundColor: 'transparent !important'}}}>
                        <TableCell colSpan={2} sx={{pt: 2, pl: 2}}>
                            {getIncomeItemTableHeader(addNewBenefitLine, "Taxable Benefit Items", "Add new benefit item", isEditable)}
                        </TableCell>
                    </TableRow>
                    {getTableBodyForIncomeLines(incomeItemsLoading, benefitItemsOnlyLines, incomeTypes, deleteIncomeLine, validateIncomeType, incomeItemsChanged, isEditable)}
                </>
                }

            </TableBody>
        </Table>
    </>
}

export default EditableIncomeLines;