import {Button, Dialog, DialogContent, DialogTitle, Divider, Grid, TextField, Typography,} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {Employee, useGetEmployeesLazyQuery} from "../../graphql/generated/graphql";
import useAnonCookie from "../../security/useAnonCookie";
import Autocomplete from '@mui/material/Autocomplete';
import EditEmployeeForm from "./EditEmployeeForm";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import useSystemNotices from "../../Utils/useSystemNotices";
import {getNumber} from "../../Utils/stringMath";
import EmployeeSelectDisplay from "./EmployeeSelectDisplay";
import {assumedUserContext} from "../User/AssumedUserContext";
import CloseDialogButton from "../../common/CloseDialogButton";

type EmployeeAutoComplete = {
    label: string;
    employee: Employee
}

const getEmployeeAutoComplete = (employee: Employee): EmployeeAutoComplete => {
    return {
        label: `${employee.detailedName}`,
        employee: employee
    }
}
const getEmployeeOptions = (employees: Array<Employee>, defaultProvinceCode: string | undefined | null): Array<EmployeeAutoComplete> => {
    let employeeOptions = employees
        .filter(employee => !employee.deleted)
        .map(employee => {
                return getEmployeeAutoComplete(employee);
            }
        );
    const newEmployeeOption: EmployeeAutoComplete = {
        label: "Add A New Employee + ",
        employee: getEmptyEmployee(defaultProvinceCode)
    };
    employeeOptions.push(newEmployeeOption);
    return employeeOptions;
}
export const getEmptyEmployee = (provinceCode?: string | undefined | null): Employee => {
    return {
        id: 0,
        firstName: "",
        lastName: "",
        detailedName: "",
        address1: undefined,
        address2: undefined,
        city: undefined,
        companyUserId: undefined,
        postalCode: undefined,
        province: provinceCode || "ON",
        eiExempt: false,
        cppExempt: false,
        federalClaimCode: "CLAIM_CODE_1",
        provincialClaimCode: "CLAIM_CODE_1",
        employerEiRatio: "1.4",
        payFrequency: "BI_WEEKLY",
        employmentProvince: provinceCode || "ON",
        deleted: false
    }
}

type PropsType = {
    close: () => void,
    employee: Employee | undefined,
    employeeAddedEdited: (employee: Employee) => void,
    employeeSelected: (employee: Employee) => void;
    hasPayStubs: boolean;
    isEditable: boolean;
}

function employeeHasId(employeeOrNew: Employee | undefined) {
    return !!employeeOrNew && !!employeeOrNew.id && getNumber(employeeOrNew.id) > 0;
}

const EmployeeSelectEdit = (props: PropsType) => {
    const {close, employee, employeeAddedEdited, employeeSelected, isEditable} = props;
    const {assumedUser} = useContext(assumedUserContext);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [employeeOrNew, setEmployeeOrNew] = useState<Employee | undefined>(employee);
    const [hasPayStubs, setHasPayStubs] = useState<boolean>(props.hasPayStubs);
    const [employeeOptions, setEmployeeOptions] = useState<Array<EmployeeAutoComplete>>([]);
    const {getAnonUserId} = useAnonCookie();
    const {sendNotice} = useSystemNotices();

    const [
        getEmployees,
        {
            data: employeesData,
            loading: employeesLoading,
            error: employeesError
        }] = useGetEmployeesLazyQuery(
        {
            variables: {
                anonUserId: getAnonUserId(),
                userId: assumedUser.id
            }
        }
    );

    function selectEmployee(newValue: Employee) {
        employeeSelected(newValue);
        close();
    }

    useEffect(() => {
        if (!!employeesData && !!employeeOrNew && !employeeHasId(employeeOrNew)) {
            setEditMode(true);
            setHasPayStubs(false);
        }
    }, [employeeOrNew, employeesData]);


    useEffect(() => {
        if (!employeesData && !employeesLoading && !employeesError) {
            getEmployees()
                .then(result => {
                    let data = result.data;
                    if (!data) {
                        sendNotice("Error getting employees in Employee select. getEmployees did not return any data.");
                    } else {
                        let employees = data?.getEmployees?.employees;
                        if (!!employees && employees.length > 0) {
                            setEmployeeOptions(getEmployeeOptions(employees, assumedUser.provinceCode));
                            if (!employeeHasId(employeeOrNew)) {
                                setEmployeeOrNew(employees[0]);
                            }
                        } else if (!!employees && employees.length < 1) {
                            setEmployeeOrNew(getEmptyEmployee(assumedUser.provinceCode));
                        }
                    }
                })
                .catch(error => {
                    sendNotice(`Error getting employees: ${error.message}`);
                });
        }
    }, [assumedUser.provinceCode, employeeOrNew, getEmployees, sendNotice, employeesData, employeesLoading, employeesError]);

    if (employeesLoading) {
        return <Typography>Loading employees</Typography>;
    }

    const noEmployeeDisplay: ReactJSXElement = <Typography>Please select an employee or create a new one.</Typography>;
    const displayEmployee: ReactJSXElement = <EmployeeSelectDisplay
        employee={employeeOrNew}
        edit={() => setEditMode(true)}
        noEmployeeDisplay={noEmployeeDisplay}
        isEditable={isEditable}
    />;

    const editEmployee: ReactJSXElement = <EditEmployeeForm
        close={close}
        employee={employeeOrNew || getEmptyEmployee(assumedUser.provinceCode)}
        employeeAddedEdited={employeeAddedEdited}
        hasPayStubs={hasPayStubs}
    />;

    const dialogContentElement = editMode ? editEmployee : displayEmployee;

    return <Dialog
        open={true}
        fullWidth={true}
        sx={{
            mt: 15,
            '& .MuiDialog-paper': {
                minWidth: {xs: 'lg', sm: 'lg', md: 'md'},
            },
        }}
    >
        <DialogTitle>
            <Grid container alignItems="center" sx={{mt: 2}}>
                {editMode &&
                    <Typography>Add / Edit Employee</Typography>
                }
                {!editMode &&
                    <Grid item xs={12}>
                        <Autocomplete
                            disabled={editMode}
                            disablePortal
                            options={employeeOptions}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Employee"
                                    fullWidth
                                    InputLabelProps={{
                                        style: {fontSize: '1.6rem'},
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        style: {fontSize: '1.4rem'},
                                    }}
                                    sx={{
                                        mt: 3,
                                        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                                            backgroundColor: 'white',
                                            paddingRight: '5px',
                                        },
                                    }}
                                />
                            )}
                            getOptionLabel={(option) => option.label}
                            renderOption={(props, option) => (
                                <li {...props} style={{fontSize: '1.4rem'}}>
                                    {option.label}
                                </li>
                            )}
                            value={getEmployeeAutoComplete(employeeOrNew || getEmptyEmployee(assumedUser.provinceCode))}
                            isOptionEqualToValue={(option, value) => option.employee.id === value.employee.id}
                            onChange={(event: any, newValue: any) => {
                                console.log("event: ", event.toString());
                                if (!!newValue) {
                                    setEmployeeOrNew(newValue.employee);
                                }
                            }}
                        />
                    </Grid>
                }

            </Grid>

            <CloseDialogButton
                close={close}
            />
        </DialogTitle>
        <DialogContent>
            {dialogContentElement}
            <Divider sx={{mt: 2, mb: 0}}/>
            <Grid container direction="row" justifyContent="space-between" spacing={2}>
                <Grid item xs={12} sm={5} md={4}>
                    {!editMode && <Button
                        onClick={() => setEmployeeOrNew(getEmptyEmployee(assumedUser.provinceCode))}
                        variant='outlined'
                        size={"large"}
                        fullWidth={true}
                        sx={{mt: 3}}
                    >
                        Add new employee
                    </Button>
                    }
                </Grid>
                <Grid item xs={12} sm={5} md={4} container direction="row"
                      justifyContent={{xs: "flex-start", sm: "flex-end"}} flexGrow={1}>
                    {!editMode && !!employeeOrNew && employeeHasId(employeeOrNew) &&
                        <Button
                            onClick={() => selectEmployee(employeeOrNew)}
                            variant='contained'
                            size={"large"}
                            fullWidth={true}
                            sx={{mt: 3}}
                            disabled={!employeeHasId(employeeOrNew)}
                        >
                            Save
                        </Button>
                    }
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>
}

export default EmployeeSelectEdit;